import React from "react";
import { CgBorderStyleDotted } from "react-icons/cg";
import { FaEdit, FaTrash, FaVideo } from "react-icons/fa"; // Import required icons
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../components/Httpapi";
import axios from "axios";
import Cookies from 'js-cookie';
import "./index.css";

const NewMeet = ({ newItem, handleJoin, fetchData }) => {
    const navigate = useNavigate();

    const onClickButton = (e) => {
        handleJoin(e.target.value);
    };

    const onClickDelete = async (e) => {
        const confirmDelete = window.confirm("Are you sure you want to cancel this meeting?");
        if (!confirmDelete) return;
        try {
            const response = await axiosBaseURL.put(`/cancel/${e.target.value}`);
            fetchData();
        } catch (error) {
            console.log("Error occurred while cancelling meeting:", error);
        }
    };

const onClickEdit=(e)=>{
        console.log(e.target.value, "saiclicked");
        navigate(`/EditMeet/${e.target.value}`)
    }

    const whenDate = new Date(newItem.meeting_date);
    const monthString = whenDate.toLocaleString('default', { month: 'short' });
    const dayOfMonth = whenDate.getDate();
    const dayOfWeekString = whenDate.toLocaleString('default', { weekday: 'short' });
    let meeting_status
    
    let meeting_status_color = "";

   if (newItem.meetingStatus==0) {
        meeting_status = "Pending";
        meeting_status_color = "blue";
    } else if (newItem.meetingStatus==1) {
        meeting_status = "Accepted";
        meeting_status_color = "green";
    } else if (newItem.meetingStatus==2) {
        meeting_status = "Cancelled";
        meeting_status_color = "red";
    }

    return (
        <li className="new-meet-item">
            <div className="calender">
                <div className="calender-details">
                    <div className="calender-item">
                        <p className="month">{monthString}</p>
                    </div>
                    <div className="day-date-container">
                        <h2 className="date">{dayOfMonth}</h2>
                        <CgBorderStyleDotted className="dots" />
                        <h2 className="day">{dayOfWeekString}</h2>
                    </div>
                </div>
            </div>

            <div className="event-details-container" style={{ borderLeftColor: meeting_status_color }}>
                <div>
                    <div className="event-name-container">
                        <h1 className="event-name">{newItem.title}</h1>
                        <p className="meeting-status" style={{ color: meeting_status_color }}>{meeting_status}</p>
                    </div>
                    <p className="event-details-para">Time: {whenDate.toLocaleTimeString()} - {newItem.end_time}</p>
                    <p className="event-details-para">Agenda: {newItem.description}</p>
                    <div className="buttons-container">
                        {newItem.meetingStatus !== 2 && (
                            <>
                                <button className="join-button" value={newItem.room_id} onClick={onClickButton}>
                                    <FaVideo />
                                </button>
                                <button className="edit-button" value={newItem.id} onClick={onClickEdit}>
                                    <FaEdit />
                                </button>
                                <button className="delete-button" value={newItem.id} onClick={onClickDelete}>
                                    <FaTrash />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </li>
    );
};

export default NewMeet;
