import { Routes, Route } from 'react-router-dom';
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Meetings from "./components/Meetings";
import AddNewMeet from './components/AddNewMeet';
import ContactUs from './components/Contactus';
import './App.css';
import Home from './components/Home';
import Price from './components/Pricing';
import Room from './components/Zegocloud-api/Room';
import Editmeet from './components/EditMeet/EditMeet';
import CalendarView from  './components/Calaender/CalendarView'
import Terms from './components/Terms';
import about from './components/Aboutus';
import WhatsAppIntegration from './components/Whatsapp';
import Privacy from './components/Privacy';
import Video from './components/Video';
import Protectrouter from './components/Protectrouter';
import UserProfileNavbar from './components/Profile';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResetPasswordForm from './components/ResetPasswordForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';



function App() {
  return (
<>
<WhatsAppIntegration/>
    <Routes>
      <Route path="/"  Component={Home} />
      <Route path="/signup" Component={SignUp} />
      <Route path="/signin"  Component={SignIn} />
     
      <Route path="/newmeeting" element={<Protectrouter><AddNewMeet /></Protectrouter>} />



      <Route path="/meetings" element={<Protectrouter><Meetings /></Protectrouter>} />

      <Route path='/profile' element={<Protectrouter><UserProfileNavbar /></Protectrouter>} />
 
      
       
      <Route path='/Contactus' Component={ContactUs} />
      <Route path='/ResetPasswordForm' Component={ResetPasswordForm} />
      <Route path='/ForgotPasswordForm' Component={ForgotPasswordForm} />
      <Route path='/pricing' Component={Price}/>
     
      <Route path='/Home' Component={Home}/>
      <Route path='/Room/:roomid' Component={Room}/>
      <Route path='/EditMeet/:id' Component={Editmeet}/>
      <Route path='/terms' Component={Terms}/>
      <Route path='/about' Component={about}></Route>
      <Route path='/Privacy' Component={Privacy}></Route>
      <Route path='/Video' Component={Video}></Route>
      <Route path='/CalendarView' Component={CalendarView}></Route>
    

      {/* <Route path='/CalendarView' Component={CalendarView}/> */}

    
    </Routes>
    </>

  );
}

export default App;
