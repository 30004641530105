import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';


const Protectrouter = ({children}) => {
    const navigate = useNavigate();
    const [jwt, setJwt] = useState('');
    useEffect(() => {
        const jwt = Cookies.get('jwt');
        const email = Cookies.get('email');
       setJwt(jwt);
       if(!jwt && !email){
        navigate('/signin')
       }
    })

    if(jwt){ 
        return(<>
        <div>
            {children}</div></>)
    }
    else{
        return( navigate('/signin'))
    }

}
export default Protectrouter;