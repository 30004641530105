import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

function Room() {
  const roomid = useParams().roomid;

  // Function to generate random ID (you can adjust this as needed)
  function randomID(len) {
    let result = '';
    var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
  }

  let myMeeting = async (element) => {
    const appID= 2102494304 ;
    const serverSecret="0cb1da1a0fd6b9879d180926c38e4fb3";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomid, randomID(5),  randomID(5));
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    
    // Logic to join the room and set up video conferencing
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference,
      },
      sharedLinks: [
        {
          name: "myMeeting",
          url: 'https://gatherhub.in/room/' + roomid
        }
      ]
    });
  };

  const startRecording = () => {
    // Logic to start recording
    // This could involve making an API request to your backend
    // to trigger the recording process
    // Example: You can use fetch or another HTTP library to send a request
    // to your backend API '/record' endpoint
    fetch('/record', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ room_id: roomid }),
    })
    .then(response => {
      if (response.ok) {
        console.log('Recording started successfully!');
        // Handle success as needed
      } else {
        console.error('Failed to start recording');
        // Handle failure as needed
      }
    })
    .catch(error => {
      console.error('Error starting recording:', error);
      // Handle error as needed
    });
  };

  return (
    <div>
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ width: '100vw', height: '100vh' }}
      ></div>
      <button onClick={startRecording}>Start Recording</button>
    </div>
  );
}

export default Room;
