import React, { useState, useEffect } from "react";
import axiosBaseURL from "../../components/Httpapi";
import { useParams, useNavigate } from "react-router-dom";
import { IoPeople } from "react-icons/io5";
import { CircleLoader } from 'react-spinners';
import "./edit.css"; // Import your CSS file
import Navbar from "../Navbar";
import UserProfileNavbar from "../Profile";

const EditMeet = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [meeting, setMeeting] = useState({
    title: "",
    description: "",
    start_time: "",
    end_time: "",
    meeting_date: ""
  });

  const [condition, updateCondition] = useState(false);

  useEffect(() => {
    axiosBaseURL.get(`/meetings/${id}`)
      .then(response => {
        const formattedJoinDate = new Date(response.data.meeting_date)
          .toISOString()
          .split("T")[0];

        setMeeting({
          title: response.data.title,
          description: response.data.description,
          start_time: response.data.start_time,
          end_time: response.data.end_time,
          meeting_date: formattedJoinDate
        });
      })
      .catch(error => {
        console.error("Error fetching meeting:", error);
      });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMeeting({ ...meeting, [name]: value });
  };

  const handleUpdate = () => {
    axiosBaseURL.put(`/update/${id}`, meeting)
      .then(response => {
        console.log("Meeting updated successfully:", response.data);
        updateCondition(true);
        setTimeout(() => {
          navigate("/meetings");
        }, 2000);
      })
      .catch(error => {
        console.error("Error updating meeting:", error);
      });
  };

  return (
    <>
    <UserProfileNavbar/>
    <div className="edit-container">
      {condition ? (
        <div className="spinnerContainer">
          <CircleLoader color="#36D7B7" size={50} />
        </div>
      ) : (
        <div className="form-container">
          <h2 className="edit-heading">Edit Meeting</h2>
          <div className="input-container">
            <label className="label-element">Title:</label>
            <input
              type="text"
              name="title"
              className="input-element"
              value={meeting.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-container">
            <label className="label-element">Start Time:</label>
            <input
              type="text"
              name="start_time"
              className="input-element"
              value={meeting.start_time}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-container">
            <label className="label-element">End Time:</label>
            <input
              type="text"
              name="end_time"
              className="input-element"
              value={meeting.end_time}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-container">
            <label className="label-element">Meeting Date:</label>
            <input
              type="date"
              name="meeting_date"
              className="input-element"
              value={meeting.meeting_date}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-container">
            <label className="label-element">Description:</label>
            <textarea
              name="description"
              className="textarea-element"
              rows="5"
              value={meeting.description}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <button onClick={handleUpdate} className="update-button">
            Update
          </button>
        </div>
      )}
    </div>
    </>
  );
};

export default EditMeet;
