import React, { useState } from "react";
import Navbar from "../Navbar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import { IoPeople } from "react-icons/io5";
import axiosBaseURL from "../../components/Httpapi";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Cookies from 'js-cookie';
import UserProfileNavbar from "../Profile";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNewMeet = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [meeting_date, setMeeting_date] = useState(new Date());
  const [start_time, setStart_time] = useState("12:00 PM");
  const [end_time, setEnd_time] = useState("12:00 PM");
  const [newAttendee, setNewAttendee] = useState(""); // State to hold the new attendee input
  const [meetingAttendees, setMeetingAttendees] = useState([]); // Array to hold all attendees
  const navigate = useNavigate();
  const notify = () => toast("Meeting added successfully!");  
  
  const jwt = Cookies.get('jwt');
  const email = Cookies.get('email');
  const [isCreating, setIsCreating] = useState(false);

  const addAttendee = () => {
    if (newAttendee.trim() !== "") {
      setMeetingAttendees([...meetingAttendees, newAttendee.trim()]);
      setNewAttendee(""); // Clear the newAttendee input field
    }
  };

  const save = async (e) => {
    e.preventDefault();
    if (!title || !description || !meeting_date || !start_time || !end_time || meetingAttendees.length === 0) {
      alert("Please fill in all fields and add at least one attendee.");
      return;
    }

    const attendeesWithSelf = [email, ...meetingAttendees];

    try {
      setIsCreating(true);

      const response = await axiosBaseURL.post("/add", {
        title,
        description,
        meeting_date,
        start_time,
        end_time,
        meeting_attendees: attendeesWithSelf,
      });

      if (response.status === 201) {
        notify();
        navigate("/meetings");
      }
    } catch (error) {
      console.error("Error adding meeting:", error);
      alert("Failed to add meeting. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <>
      <UserProfileNavbar />
      <div className="addnewmeet">
        <div className="form-containers1">
          <IoMdArrowRoundBack className="meeting-label" onClick={() => navigate("/meetings")} />
          <h2 className="form-heading">Add New Meeting</h2>
          <form onSubmit={save}>
            <div className="input-row">
              <div className="forminput-container">
                <label className="input-label">Title:</label>
                <input
                  className="input-field"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="forminput-container">
                <label className="input-label">Meeting Date:</label>
                <DatePicker
                  selected={meeting_date}
                  onChange={(date) => setMeeting_date(date)}
                  className="input-field"
                />
              </div>
            </div>
            <div className="input-row">
              <div className="forminput-container">
                <label className="input-label">Start Time:</label>
                <TimePicker
                  onChange={setStart_time}
                  value={start_time}
                  className="time-picker"
                  disableClock={true}
                />
              </div>
              <div className="forminput-container">
                <label className="input-label">End Time:</label>
                <TimePicker
                  onChange={setEnd_time}
                  value={end_time}
                  className="time-picker"
                  disableClock={true}
                />
              </div>
            </div>
            <div className="forminput-container">
              <label className="input-label">Attendees:</label>
              <div className="attendee-input-container">
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter attendee email"
                  value={newAttendee}
                  onChange={(e) => setNewAttendee(e.target.value)}
                />
                <button type="button" style={{ marginLeft: "10px" , backgroundColor: "#2196f3", color: "#fff", borderRadius: "5px",marginTop: "8px"}   } onClick={addAttendee}>Add</button>
              </div>
              <ul className="attendee-list">
                {meetingAttendees.map((attendee, index) => (
                  <li key={index}>{attendee}</li>
                ))}
              </ul>
            </div>
            <div className="forminput-container">
              <label className="input-label">Description:</label>
              <textarea
                className="textarea-field"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter agenda / description"
              />
            </div>
            {isCreating && <div className="progress">Creating...</div>}
            <div className="button-container">
              <button type="submit" className="submit-button">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewMeet;
