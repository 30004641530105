import React, { useState } from 'react';
import './index.css'; // Import your CSS file
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleNav = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav>
        <div className="logo">
          <h1>Gather Hub</h1>
        </div>
        <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          {/* Render menu items */}
          <li>
            <a href="/Home">Home</a>
          </li>
          <li>
            <a href="/Pricing">Pricing</a>
          </li>
         
          <li>
            <a href="/Contactus">Contact Us</a>
          </li>
        </ul>
        {/* Buttons for Login and Register */}
        <div className={`nav-buttons ${isMenuOpen ? 'active' : ''}`}>
          <Link to={"/signin"}>
          <button className="login-button">Login</button> </Link>
          <Link to={"/signup"}>
          <button className="register-button">Register</button> </Link>
        </div>
        {/* Hamburger icon for mobile */}
        <div className={`hamburger ${isMenuOpen ? 'hamburger-active' : ''}`} onClick={toggleNav}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div className={`menubar ${isMenuOpen ? 'active' : ''}`}>
        <ul>
          {/* Render menu items */}
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#">Services</a>
          </li>
          <li>
            <a href="#">Blog</a>
          </li>
          <li>
            <a href="/contact-us">Contact Us</a>
          </li>
        </ul>
        {/* Buttons for Login and Register in mobile menu */}
        {/* <div className="mobile-buttons">
          <button className="login-button">Login</button>
          <button className="register-button">Register</button>
        </div> */}
      </div>
    </>
  );
};

export default Navbar;
