import React, { useEffect, useState } from 'react';
import "./index.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { IoVideocam } from "react-icons/io5";
import { BiChat } from "react-icons/bi";
import { FaPhoneAlt, FaChalkboardTeacher } from "react-icons/fa";
import { WiStars } from "react-icons/wi";
import { MdMarkEmailUnread, MdOutlinePeopleOutline, MdAppRegistration } from "react-icons/md";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const jwt = Cookies.get('jwt');
        if (jwt) {
            navigate('/meetings');
        }
    }, []);
    return (
        <>
            <Navbar />
            <div id="top-section">
                <div className="top-text-container">
                    <h2>Unified Communication and Collaboration Platform</h2>
                    <p>Make meaningful connections with meetings, team chat, whiteboard, phone, and more in one comprehensive offering.</p>
                    <div className="features-container">
                        <div className="feature">
                            <IoVideocam className="feature-icon" />
                            <p>Virtual Meetings</p>
                        </div>
                        <div className="feature">
                            <BiChat className="feature-icon" />
                            <p>Team Chat</p>
                        </div>
                        <div className="feature">
                            <FaPhoneAlt className="feature-icon" />
                            <p>VoIP Phone System</p>
                        </div>
                        <div className="feature">
                            <FaChalkboardTeacher className="feature-icon" />
                            <p>Online Whiteboard</p>
                        </div>
                        <div className="feature">
                            <WiStars className="feature-icon" />
                            <p>AI Integration</p>
                        </div>
                        <div className="feature">
                            <MdMarkEmailUnread className="feature-icon" />
                            <p>Email and Calendar</p>
                        </div>
                        <div className="feature">
                            <MdOutlinePeopleOutline className="feature-icon" />
                            <p>Virtual Workspace</p>
                        </div>
                        <div className="feature">
                            <MdAppRegistration className="feature-icon" />
                            <p>Appointment Scheduler</p>
                        </div>
                    </div>
                </div>
                <img className="top-section-image" src="https://blog.ipleaders.in/wp-content/uploads/2021/05/Meeting_Presentation_Conference-1.jpg" alt="Top Section Image" />
            </div>

            <div className="thrive-content">
                <h2>Transform with Us. Innovate with Us. Thrive with Us.</h2>
            </div>

            <div className="about-section">
                <div className="about-card">
                    <h2>Our Core Values</h2>
                    <p>
                        <strong>Commitment:</strong> At the heart of our approach lies a deep commitment to our clients' success. We forge strong partnerships built on trust, transparency, and collaboration.
                    </p>
                    <p>
                        <strong>Timely Delivery:</strong> We understand the importance of timely project delivery in today's fast-paced business environment. Our dedicated teams ensure projects are delivered on time.
                    </p>

                    <p>
                        <strong>Customer Focus:</strong> We take the time to understand our clients' needs and provide customized solutions. We strive to exceed expectations and exceed expectations.
                    </p>
                    
                </div>
                <div className="about-card">
                    <h2>Our Services</h2>
                    <p>
                        <strong>Virtual Meetings:</strong> Host interactive virtual meetings with video conferencing, screen sharing, and collaboration tools.
                    </p>
                    <p>
                        <strong>Team Collaboration:</strong> Foster teamwork with integrated chat, file sharing, and project management features.
                    </p>
                    <p>
                        <strong>VoIP Phone System:</strong> Seamlessly connect with clients and colleagues through a reliable VoIP phone system.
                    </p>
                    <p>
                        <strong>AI Integration:</strong> Leverage AI for intelligent scheduling, analytics, and automation within your workflows and projects.
                    </p>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Home;
