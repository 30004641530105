import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axiosBaseURL from '../Httpapi';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserProfileNavbar from '../Profile';
import { useNavigate } from 'react-router-dom';

const localizer = momentLocalizer(moment);

const CalendarView = () => {
  const [events, setEvents] = useState([]);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const email = Cookies.get('email');
  const navigate = useNavigate();

  const handleNewMeeting = () => {
    navigate("/newmeeting");
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const response = await axiosBaseURL.post('/get-meet-by-email', { email: email });
        const meetings = response.data.map(meeting => {
          const meetingDate = new Date(meeting.meeting_date);
          const startDateTime = new Date(
            meetingDate.getFullYear(),
            meetingDate.getMonth(),
            meetingDate.getDate(),
            ...meeting.start_time.split(':').map(Number)
          );
          const endDateTime = new Date(
            meetingDate.getFullYear(),
            meetingDate.getMonth(),
            meetingDate.getDate(),
            ...meeting.end_time.split(':').map(Number)
          );

          return {
            title: meeting.title,
            start: startDateTime,
            end: endDateTime,
            description: meeting.description,
            allDay: false,
            meetingStatus: meeting.meetingStatus
          };
        });
        setEvents(meetings);
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };

    fetchMeetings();
  }, [email]);

  const eventStyleGetter = event => {
    let style = {
      backgroundColor: '#3174ad',
      borderRadius: '0px',
      height: '60px',
      
      color: 'white',
      border: '0px',
      display: 'flex'
    };

    if (event.meetingStatus === 2) {
      style = {
        ...style,
        textDecoration: 'line-through',
        backgroundColor: 'lightgrey' // Change color for cancelled meetings
      };
    }

    return { style };
  };

  const EventTooltip = ({ event }) => (
    <Tooltip id="event-tooltip">
      <div>
        <strong>{event.title}</strong>
      </div>
      <div>{event.description}</div>
      <div>Start: {moment(event.start).format('HH:mm')}</div>
      <div>End: {moment(event.end).format('HH:mm')}</div>
    </Tooltip>
  );

  const Event = ({ event }) => (
    <OverlayTrigger     placement="top" overlay={<EventTooltip event={event} />}>
      <div >
        <strong>{event.title}</strong>
        
        {event.meetingStatus === 0 && (
          <button className="join-button">Join</button>
        )}
        {event.meetingStatus === 1 && <div>Completed</div>}
        {event.meetingStatus === 2 && <div>Cancelled</div>}
      </div>
    </OverlayTrigger>
  );

  const handleSelectSlot = ({ start }) => {
    const selectedEvents = events.filter(event => moment(event.start).isSame(start, 'day'));
    setSelectedDateEvents(selectedEvents);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDateEvents([]);
  };

  return (
    <div>
      <UserProfileNavbar />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', textAlign: 'center', marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}>
        <button className="new-meeting-button" onClick={handleNewMeeting}>
          New Meeting
        </button>
      </div>

      <Calendar
        localizer={localizer}
        events={events}
        views={['month', 'day']}
        defaultView={'month'}
        showMultiDayTimes={false}
        style={{ height:900, width: '100%' }}
        components={{ event: Event }}
        eventPropGetter={eventStyleGetter}
        selectable={true}
        onSelectSlot={handleSelectSlot}
      />

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Meetings on {moment(selectedDateEvents[0]?.start).format('MMMM Do YYYY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDateEvents.map((event, index) => (
            <div key={index}      className="event-card">
              <strong>{event.title}</strong>
              <div>{event.description}</div>
              <div>Start: {moment(event.start).format('HH:mm')}</div>
              <div>End: {moment(event.end).format('HH:mm')}</div>
              {event.meetingStatus === 0 && (
                <button className="join-button">Join</button>
              )}
              {event.meetingStatus === 1 && <div>Completed</div>}
              {event.meetingStatus === 2 && <div>Cancelled</div>}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalendarView;
