import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import signup1 from '../images/signup1.png';
import './index.css';
import axiosBaseURL from "../Httpapi";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const notify = () => toast("Login successfull!");

    useEffect(() => {
        const jwt = Cookies.get('jwt');
        if (jwt) {
            navigate('/meetings');
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (email.trim() === '' || password.trim() === '') {
            alert('Please fill in all fields.');
            return;
        }
        
        try {
            const response = await axiosBaseURL.post('/authenticate', {
                email,
                password,
            });

            const { jwt: { jwt: token, email: userEmail } } = response.data;

            Cookies.set('jwt', token, { expires: 7 });
            Cookies.set('email', userEmail);
            console.log('Login Successful');
            notify();
            navigate('/meetings', { replace: true });
            
        } catch (error) {
            console.error('Login Error:', error);
            alert('Login Failed. Please check your credentials.');
        }
    };

    const onClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Navbar />
            <nav className="loginpage">
                <div className="container">
                    <input type="checkbox" id="flip" />
                    <div className="cover">
                        <div className="front">
                            <img src={signup1} alt="front image" />
                            <div className="text">
                                <span className="text-1">Every new friend is a <br /> new adventure</span>
                                <span className="text-2">Let's get connected</span>
                            </div>
                        </div>
                        <div className="back">
                            <div className="text">
                                <span className="text-1">Complete miles of journey <br /> with one step</span>
                                <span className="text-2">Let's get started</span>
                            </div>
                        </div>
                    </div>
                    <div className="forms">
                        <div className="form-content">
                            <div className="login-form">
                                <div className="title">Login</div>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-boxes">
                                        <div className="input-box">
                                            <i className="fas fa-envelope"></i>
                                            <input
                                                type="text"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-box">
                                            <i className="fas fa-lock"></i>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Enter your password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <i
                                                className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                onClick={onClickShowPassword}
                                            ></i>
                                        </div>
                                        <div className="text"><a href="#">Forgot password?</a></div>
                                        <div className="button input-box">
                                            <input type="submit" value="Submit" />
                                        </div>
                                        <div className="text sign-up-text">
                                            Don't have an account? <label htmlFor="flip">Sign up now</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default SignIn;
