import React, { useState } from 'react';

const ResetPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch('http://localhost:8083/reset-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, otp, newPassword })
        });
        alert("Password has been reset successfully");
    }

    return (
        <form onSubmit={handleSubmit}>
            <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Enter your email"
                required 
            />
            <input 
                type="text" 
                value={otp} 
                onChange={(e) => setOTP(e.target.value)} 
                placeholder="Enter OTP"
                required 
            />
            <input 
                type="password" 
                value={newPassword} 
                onChange={(e) => setNewPassword(e.target.value)} 
                placeholder="Enter new password"
                required 
            />
            <button type="submit">Reset Password</button>
        </form>
    );
}

export default ResetPasswordForm;
