import React from 'react';
import './index.css'; // Assuming you have a CSS file for styling
import Navbar from '../Navbar';
import Footer from '../Footer';
import { colors } from '@mui/material';

const PricingTable = () => {
  return (
    <>
    <Navbar />
    <section>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="card text-center">
                <div className="title">
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  <h2>Basic <span className='pricebasic'>1 user</span> </h2>
                  
                </div>
                <div className="price">
                  <h4>Free</h4>
                </div>
                <div className="option">
  <ul>
    <li><i className="fa fa-check" aria-hidden="true"></i> Meetings upto 30 minutes </li>
    <li><i className="fa fa-check" aria-hidden="true"></i> 10 Participants per meeting </li>
    <li><i className="fa fa-check" aria-hidden="true"></i> Team Chat</li>
    <li><i className="fa fa-check" aria-hidden="true"></i> Mail and Calendar </li>
  </ul>
</div>

                <a href="/signup">Signup  Now </a>
              </div>
            </div>
            {/* END Col one */}
            <div className="col-sm-4">
              <div className="card text-center">
                <div className="title">
                  <i className="fa fa-plane" aria-hidden="true"></i>
                  <h2>Standard</h2>
                </div>
                <div className="price">
                  <h4><sup>$</sup>50</h4>
                </div>
                <div className="option">
                  <ul>
                    <li> <i className="fa fa-check" aria-hidden="true"></i> Meetings upto 60 minutes</li>
                    <li> <i className="fa fa-check" aria-hidden="true"></i>30 Participants per meeting </li>
                    <li> <i className="fa fa-check" aria-hidden="true"></i> Team chat </li>
                    <li> <i className="fa fa-times" aria-hidden="true"></i> Mail and Calendar </li>

                  </ul>
                </div>
                <a href="#">Buy Now </a>
              </div>
            </div>
            {/* END Col two */}
            <div className="col-sm-4">
              <div className="card text-center">
                <div className="title">
                  <i className="fa fa-rocket" aria-hidden="true"></i>
                  <h2>Premium</h2>
                </div>
                <div className="price">
                  <h4><sup>$</sup>100</h4>
                </div>
                <div className="option">
                  <ul>
                    <li> <i className="fa fa-check" aria-hidden="true"></i> Meetings upto 100 minutes </li>
                    <li> <i className="fa fa-check" aria-hidden="true"></i> 50 Participants per meeting </li>
                    <li> <i className="fa fa-check" aria-hidden="true"></i> Team chat </li>
                    <li> <i className="fa fa-check" aria-hidden="true"></i> Mail and Calendar </li>
                  </ul>
                </div>
                <a href="#">Buy Now </a>
              </div>
            </div>
            {/* END Col three */}
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
    
    
  );
};

export default PricingTable;
