import React from 'react';
import './index.css'; // Assuming you have a CSS file for styling
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../components/Httpapi";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const notify = () => toast("Contact  sent successfull!");

const save = async (e) => {
  e.preventDefault();
  if (!name || !email || !message) {
    alert("Please fill in all fields");
    return;
  }
  try {
    const response = await axiosBaseURL.post('/saveContact', {
      name,
      email,
      message
    });
    if (response.status === 200) {
      notify();
      navigate("/Home");
    }
  }
  
    catch (error) {
    console.error("Error sending message:", error);
    alert("Failed to send message. Please try again.");
  }
}

  return (
    <>
    <Navbar/>
    <nav className='contactform'>
    <div className="container">
      <div className="content">
        <div className="left-side">
          <div className="address details">
            <i className="fas fa-map-marker-alt"></i>
            <div className="topic">Address</div>
            <div className="text-one">Labyrinth Globals Soutions</div>
            <div className="text-two">5th Floor, Madhapur</div>
          </div>
          <div className="phone details">
            <i className="fas fa-phone-alt"></i>
            <div className="topic">Phone</div>
            <div className="text-one"> 040 4602 5358</div>
          
          </div>
          <div className="email details">
            <i className="fas fa-envelope"></i>
            <div className="topic">Email</div>
 
            <div className="text-two">info@labyrinthglobalsolutions</div>
          </div>
        </div>
        <div className="right-side">
          <div className="topic-text">Send us a message</div>
          <p>
            If you have any work for me or any types of queries related to my tutorials, you can send me a message from here. It's my pleasure to help you.
          </p>
          <form action="#" onSubmit={save}>
            <div className="input-box">
              <input  type="text" placeholder="Enter your name"
              value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="input-box">
              <input type="text" placeholder="Enter your email" 
              value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="input-box message-box">
              {/* Assuming you want a text area for the message */}
             <input type="text" placeholder="Enter your message" 
             value={message} onChange={(e) => setMessage(e.target.value)}/>
            </div>
            <div className="contactbutton">
              <input type="submit" value="Send Now"/>
            </div>
          </form>
        </div>
      </div>
    </div>
    </nav>
    <Footer />
    </>
  );
};

export default ContactForm;
