import React, { useState } from "react";
import "./index.css"; // Import your custom CSS styles
import axiosBaseURL from "../../components/Httpapi"; // Import your axios instance
import Navbar from "../Navbar";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const notify = () => toast("Sign up successful!");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const checkEmailExists = async () => {
    try {
      const response = await axiosBaseURL.get(`/check-email?email=${email}`);
      const emailExists = response.data;
      setEmailExistsError(emailExists);
    } catch (err) {
      console.error("Error checking email:", err);
    }
  };

  const signUpUser = async (event) => {
    event.preventDefault();

    // Check if email already exists
    await checkEmailExists();

    if (emailExistsError) {
      alert("Email already exists. Please use a different email.");
      return;
    }

    // Validate form fields
    if (username === '' || email === '' || password === '' || confirmPassword === '') {
      alert('Please fill in all required fields.');
      return;
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    try {
      // Send registration data to backend
      await axiosBaseURL.post("/sign-up", {
        username: username,
        email: email,
        password: password,
      });

      // Display success message and navigate to Signin page
      notify();
      navigate("/signin");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Navbar />
      <div className="signuppage">
        <div className="signup-main-container">
          <section className="container">
            <header>Registration Form</header>
            <form onSubmit={signUpUser} className="form">
              <div className="input-box">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  value={username}
                  onChange={handleUsernameChange}
                  required
                />
              </div>
              <div className="input-box">
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter email address"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="input-box">
                <label>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div className="input-box">
                <label>Confirm Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </div>
              <div className="checkbox-box">
                <input
                  type="checkbox"
                  id="termsCheckbox"
                  checked={termsChecked}
                  onChange={handleTermsChange}
                />
                <label htmlFor="termsCheckbox">Accept terms & conditions</label>
              </div>
              <button className="signupbutton" type="submit">Submit</button>
            </form>
          </section>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignUp;
