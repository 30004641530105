import React, { useState, useEffect } from 'react';
import './index.css'; // Import your CSS file
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const UserProfileNavbar = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    const userEmailFromCookie = Cookies.get('email');
    if (userEmailFromCookie) {
      setUserEmail(userEmailFromCookie);
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleLogout = () => {
    // Clear JWT token and email from cookies
    Cookies.remove('token');
    Cookies.remove('email');
    Cookies.remove('jwt');
    // Close the popup after logout
    setIsPopupOpen(false);
    navigate('/Home');
  };

  return (
    <nav className="user-profile-navbar">
      <div className="logo">
        <h1>Gather Hub</h1>
      </div>
        
         <div style={{ display: 'flex', gap: '15px',textDecoration: 'none' }} >
        <a href="/Home" style={{ textDecoration: 'none', color: 'black' }}>Home</a>
        <a href="/CalendarView" style={{ textDecoration: 'none', color: 'black' }}>Calendar</a>
      </div>


      <div className="user-info">
        <div className="profile-icon" onClick={() => setIsPopupOpen(!isPopupOpen)}>
          <FontAwesomeIcon icon={faUser} className="user-icon" />
        </div>
        <p>{userEmail}</p>
      </div>
      {isPopupOpen && (
        <div className="popup">
          <button className="logout-button" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
            Logout
          </button>
        </div>
      )}
    </nav>
  );
};

export default UserProfileNavbar;
