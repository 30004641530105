import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import UserProfileNavbar from "../Profile";
import NewMeet from "../NewMeet";
import axiosBaseURL from "../../components/Httpapi";
import "./index.css";
import { v4 as uuidv4 } from 'uuid';



const myMeetings = [{ id: uuidv4(),  who: "akhila@gamil.com", agenda: "developer testing" }, { id: uuidv4(),  who: "akhila@gamil.com", agenda: "developer testing" }, { id: uuidv4(),  who: "akhila@gamil.com", agenda: "developer testing" }, { id: 4,  who: "akhila@gamil.com", agenda: "developer testing" }]
 
const Meetings = () => {
    const [meetings, setMeetings] = useState([]);
    const navigate = useNavigate();
    const jwt = Cookies.get('jwt');
    const email = Cookies.get('email');

    const handleJoin = (roomId) => {
        navigate(`/room/${roomId}`);
    };

    const fetchMeetings = async () => {
        try {
            const response = await axiosBaseURL.post(
                '/get-meet-by-email',
                { email: email }
            );
    
            if (Array.isArray(response.data)) {
                setMeetings(response.data);
            } else {
                setMeetings([]);
            }
        } catch (error) {
            console.error('Error fetching meetings:', error);
            setMeetings([]);
        }
    };
   
    useEffect(() => {
        fetchMeetings();
    }, []); // Empty dependency array triggers fetchMeetings only on component mount

    const handleNewMeeting = () => {
        navigate("/newmeeting");
    };

    return (
        <>
            <UserProfileNavbar />
             




            <div className="meetings-main-container">
                <div className="new-button-container">
                    <button
                        onClick={handleNewMeeting}
                        className="new-meeting-button"
                        type="button"
                    >
                        New Meeting
                    </button>
                </div>

                <div className="meetings-cards-container">
                    {meetings.map((meeting) => (
                        <NewMeet
                            key={meeting.id}
                            newItem={meeting}
                            handleJoin={handleJoin}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default Meetings;
